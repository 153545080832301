<template>
    <a-modal v-model="show" title="通知公告分类" :width="600" :footer="null" @cancel="() => { $emit('success'); show = false; }"
             centered destroyOnClose>

        <a-table :scroll="{ x: '100%', y: '300px' }" :style="{ background: '#FFF' }" size="small" :columns="keys" ref="list"
                 :data-source="category" :bordered="false" :pagination="false">
            <!--名称-->
            <template slot-scope="text,row,index" slot="name">
                <a-input v-model="row.name" v-if="row.edit" @pressEnter="confirmRow(row, index)"></a-input>
                <span v-else>{{ row.name }}</span>
            </template>
            <!--排序-->
            <template slot-scope="text,row,index" slot="sort">
                <a-input-number v-model="row.sort" align="center" v-if="row.edit"
                                @pressEnter="confirmRow(row, index)"></a-input-number>
                <span v-else>{{ row.sort }}</span>
            </template>
            <template slot-scope="text,row,index" slot="isSys">
                <a-switch size="small" v-model="row.isSys" align="center" v-if="row.edit"></a-switch>
                <!-- <span v-else>{{ row.isSys }}</span> -->
                <a-switch size="small" v-model="row.isSys" align="center" v-else
                          @change="confirmRow(row, index)"></a-switch>
            </template>
            <!--操作-->
            <template slot-scope="text,row,index" slot="action">
                <a-button-group shape="circle" size="small">
                    <a-tooltip title="编辑" v-if="!row.edit">
                        <a-button icon="edit" @click="editRow(row, index)"></a-button>
                    </a-tooltip>
                    <a-tooltip title="保存" v-else>
                        <a-button icon="check" @click="confirmRow(row, index)"></a-button>
                    </a-tooltip>
                    <a-tooltip title="取消" v-if="row.edit"><a-button icon="close"
                                  @click="delRow(row, index)"></a-button></a-tooltip>
                    <a-tooltip title="删除" v-else><a-button icon="delete" @click="delRow(row, index)"></a-button></a-tooltip>
                </a-button-group>
            </template>
            <template slot="footer">
                <div class="footer">
                    <a-button type="primary" @click="addRow" size="small" icon="plus">添加新分类</a-button>
                </div>
            </template>
        </a-table>
    </a-modal>
</template>

<script>
import request from "@/config/request";
import utils from "@/common/utils";
import apiUtil from "@/common/apiUtil";
export default {
    data() {
        return {
            show: false,
            loading: false,
            keys: [
                { title: '分类名称', scopedSlots: { customRender: 'name' }, width: 80, ellipsis: true, toolbar: true },
                { title: '排序', scopedSlots: { customRender: 'sort' }, align: 'center', width: 80, ellipsis: true, sorter: (a, b) => a.sort - b.sort },
                { title: '是否为系统分类', scopedSlots: { customRender: 'isSys' }, align: 'center', width: 100, ellipsis: true, },
                { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
            ],
            category: []
        };
    },
    methods: {
        ...apiUtil,
        addRow() {
            const item = {
                categoryId: '',
                name: '',
                organId: this.organId,
                sort: 0,
                edit: true,
                isSys: false,
            };
            this.category.push(item);
        },
        delRow(row, index) {
            if (!row.categoryId) {
                this.category.splice(index, 1);
                return;
            }
            if (row.edit) {
                row.edit = false;
                this.category.splice(index, 1, row);
                return;
            }
            utils.confirm("您确认要删除此分类吗?").then(() => {
                utils.showSpin();
                request.delete("/platform/notice/category/del/" + row.categoryId).then(ret => {
                    this.category.splice(index, 1);
                });
            });
        },
        editRow(row, index) {
            row.edit = true;
            this.category.splice(index, 1, row);
        },
        confirmRow(row, index) {
            delete row.notices;
            utils.showSpin();
            request.post("/platform/notice/category/save", row).then(ret => {
                row.edit = false;
                this.category.splice(index, 1, row);
            });
        },
        _show() {
            this.getCategoryList();
            this.show = true;

        },
        getCategoryList() {
            this.http.get("/platform/notice/category", { organId: apiUtil.getOrganId() }).then(ret => {
                this.category = ret.data;
            });
        },
        _close() {
            console.log(1);
            this.show = false;
        }
    },
    props: {
        // category: {
        //     type: Array,
        //     default: () => {
        //         return [];
        //     }
        // },
        organId: {
            type: Number | String,
            default: () => {
                return apiUtil.getOrganId();
            }
        }
    }
};
</script>
<style>
.footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>